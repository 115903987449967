// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login{
    background-color: gainsboro;
    padding:10px;
    min-width: 330px;
    height: 800px;
  }

  .libutton{
    position: relative;
    background-color: rgb(230, 34, 77);
    border-radius: 5px;
    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
    padding: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    width: 150px;
    height: 40px;
    color: #fff;
    border: none;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    overflow: hidden;
  }
  .userName{
    font-size: xx-large;
    color: black;
    font-weight: bolder;
    text-decoration: underline;
  }

.oneRow{
  display: flex;
  justify-content: space-between;
}

.card{
  margin-top: 50px;
  
}

.betCard{
  margin-bottom: 15px;
  font-size: larger;
  text-align: center;
  padding:5px;
}

.betTitle{
  font-size: small;
  font-style: italic;
}

.betDate{
  font-weight: bolder;
  font-size: x-large;
}`, "",{"version":3,"sources":["webpack://./src/Record.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,kCAAkC;IAClC,kBAAkB;IAClB,4CAA4C;IAC5C,aAAa;IACb,4BAA4B;IAC5B,eAAe;IACf,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gCAAgC;IAChC,UAAU;IACV,gBAAgB;EAClB;EACA;IACE,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;EAC5B;;AAEF;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;;AAElB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".login{\n    background-color: gainsboro;\n    padding:10px;\n    min-width: 330px;\n    height: 800px;\n  }\n\n  .libutton{\n    position: relative;\n    background-color: rgb(230, 34, 77);\n    border-radius: 5px;\n    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;\n    padding: 15px;\n    background-repeat: no-repeat;\n    cursor: pointer;\n    box-sizing: border-box;\n    width: 150px;\n    height: 40px;\n    color: #fff;\n    border: none;\n    font-size: 20px;\n    transition: all 0.3s ease-in-out;\n    z-index: 1;\n    overflow: hidden;\n  }\n  .userName{\n    font-size: xx-large;\n    color: black;\n    font-weight: bolder;\n    text-decoration: underline;\n  }\n\n.oneRow{\n  display: flex;\n  justify-content: space-between;\n}\n\n.card{\n  margin-top: 50px;\n  \n}\n\n.betCard{\n  margin-bottom: 15px;\n  font-size: larger;\n  text-align: center;\n  padding:5px;\n}\n\n.betTitle{\n  font-size: small;\n  font-style: italic;\n}\n\n.betDate{\n  font-weight: bolder;\n  font-size: x-large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
