// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login{
    background-color: gainsboro;
    padding:10px;
    min-width: 330px;
    height: 800px;
  }
  h1 {
    color: black;
    text-align: center;
    font-size: 60px;
  }
  
  .centeredinput{
    padding: 20px 15px 10px 15px;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #333;
    font-size: 16px;
    font-weight: 550;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 0 5px transparent;
  }
  
  .libutton{
    position: relative;
    background-color: rgb(230, 34, 77);
    border-radius: 5px;
    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
    padding: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    width: 220px;
    height: 40px;
    color: #fff;
    border: none;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    overflow: hidden;
  }
  
  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    align-content: space-between;
    align-items: center;
  }
  
  .inputfield{
    padding:20px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;IAChB,aAAa;EACf;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,4BAA4B;IAC5B,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,iCAAiC;EACnC;;EAEA;IACE,kBAAkB;IAClB,kCAAkC;IAClC,kBAAkB;IAClB,4CAA4C;IAC5C,aAAa;IACb,4BAA4B;IAC5B,eAAe;IACf,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gCAAgC;IAChC,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".login{\n    background-color: gainsboro;\n    padding:10px;\n    min-width: 330px;\n    height: 800px;\n  }\n  h1 {\n    color: black;\n    text-align: center;\n    font-size: 60px;\n  }\n  \n  .centeredinput{\n    padding: 20px 15px 10px 15px;\n    outline: none;\n    border: none;\n    border-radius: 5px;\n    background-color: #f1f1f1;\n    color: #333;\n    font-size: 16px;\n    font-weight: 550;\n    transition: 0.3s ease-in-out;\n    box-shadow: 0 0 0 5px transparent;\n  }\n  \n  .libutton{\n    position: relative;\n    background-color: rgb(230, 34, 77);\n    border-radius: 5px;\n    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;\n    padding: 15px;\n    background-repeat: no-repeat;\n    cursor: pointer;\n    box-sizing: border-box;\n    width: 220px;\n    height: 40px;\n    color: #fff;\n    border: none;\n    font-size: 20px;\n    transition: all 0.3s ease-in-out;\n    z-index: 1;\n    overflow: hidden;\n  }\n  \n  form{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 200px;\n    align-content: space-between;\n    align-items: center;\n  }\n  \n  .inputfield{\n    padding:20px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
